import { ethers } from 'ethers'
import { getAddress } from '@ethersproject/address'
import { defaultChainId } from '../config/constants'
import getRpcUrl from './getRpcUrl'

export const getLibrary = (provider = null) => provider

export const formatAddress = (address) => {
  return `${address.slice(0, 6)}...${address.slice(-6)}`
}

const chainNames = {
  59140: 'Linea Goerli',
  59144: 'Linea',
}

export const getScanUrl = () => {
  if (defaultChainId === 146) {
    return 'https://sonicscan.org/'
  }
  if (defaultChainId === 56) {
    return `https://bscscan.com/`
  }
  if (defaultChainId === 97) {
    return `https://testnet.bscscan.com/`
  }
  if (defaultChainId === 59140) {
    return `https://goerli.lineascan.build/`
  }
  if (defaultChainId === 59144) {
    return `https://lineascan.build/`
  }
}

export const getNetworkLibrary = () => {
  return new ethers.providers.JsonRpcProvider(getRpcUrl(), defaultChainId)
}

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
}

export const getChainData = () => {
  return {
    chainId: `0x${defaultChainId.toString(16)}`,
    chainName: chainNames[defaultChainId],
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: [getRpcUrl()],
    blockExplorerUrls: [getScanUrl()],
  }
}

export const isAddress = (value) => {
  try {
    return getAddress(value || '')
  } catch {
    return false
  }
}

// Captures 0x + 4 characters, then the last 4 characters.
const truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/

/**
 * Truncates an ethereum address to the format 0x0000…0000
 * @param address Full address to truncate
 * @returns Truncated address
 */
export const truncateEthAddress = (address) => {
  const match = address.match(truncateRegex)
  if (!match) return address
  return `${match[1]}…${match[2]}`
}
