import React, { useContext, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, useColorMode, defineStyle } from '@chakra-ui/react'
import dayjs from 'dayjs'

import AnalyticsHeader from '../../../components/pages/analyticsHeader'
import { useBulkPairData } from '../../../context/PairData'
import { useTokenChartData, useTokenData, useTokenPairs } from '../../../context/TokenData'
import PairsTable from '../../../components/pages/pairsTable'
import { BaseAssetsConetext } from '../../../context/BaseAssetsConetext'
import { formatAmount } from '../../../utils/formatNumber'
import PercentChange from '../../../components/common/PercentChange'
import { AnalyticChart, timeframeOptions } from '../../../config/constants'
import AreaChart from '../../../components/common/AreaChart'
import { getYAXISValuesAnalytics, getChartDates } from '../../../utils/analyticsHelper'
import { getScanUrl } from '../../../utils'
import { cn } from '../../../helpers/tailwind'
import LinkIcon from '../../../components/icons/link'
import TimeframeFilter from '../../../components/common/TimeframeFilter'

const ChartIndexes = {
  Volume: 'Volume',
  Liquidity: 'Liquidity',
  Price: 'Price',
}

const linkIconStyle = defineStyle({
  width: '14px',
  height: 'auto',
})

const TokenDetails = () => {
  const [chartIndex, setChartIndex] = useState(ChartIndexes.Volume)
  const [timeframe, setTimeframe] = useState(timeframeOptions['WEEK'])

  const { id: tokenAddress } = useParams()
  const navigate = useNavigate()
  const { colorMode } = useColorMode()
  const tokenData = useTokenData(tokenAddress)
  const tokenChartData = useTokenChartData(tokenAddress)

  const {
    name,
    symbol,
    priceUSD,
    priceOneDayChangeUSD,
    volumeOneDayUSD,
    volumeOneDayChangeUSD,
    liquidityUSD,
    liquidityUSDOneDayChange,
    oneDayTxns,
    txnChange,
  } = tokenData
  const allPairs = useTokenPairs(tokenAddress)
  const pairs = useBulkPairData(allPairs)
  const assets = useContext(BaseAssetsConetext)

  const pairsWithImg = useMemo(() => {
    if (Object.keys(pairs).length > 0)
      return Object.values(pairs).map((pair) => {
        const found0 = assets.find((ele) => ele.address.toLowerCase() === pair.token0.id)
        const found1 = assets.find((ele) => ele.address.toLowerCase() === pair.token1.id)
        return {
          ...pair,
          token0: {
            ...pair.token0,
            name: found0 ? found0.name : pair.token0.name,
            symbol: found0 ? found0.symbol : pair.token0.symbol,
            logo: found0 ? found0.logo : '/images/tokens/UKNOWN.png',
          },
          token1: {
            ...pair.token1,
            name: found1 ? found1.name : pair.token1.name,
            symbol: found1 ? found1.symbol : pair.token1.symbol,
            logo: found1 ? found1.logo : '/images/tokens/UKNOWN.png',
          },
        }
      })
    return []
  }, [pairs])
  const asset = assets.find((ele) => ele.address.toLowerCase() === tokenAddress)

  // const result = useMemo(() => {
  //   if (!tokenChartData) return
  //   const res = [...tokenChartData]
  //   if (chartIndex === ChartIndexes.Volume) {
  //     res.pop()
  //   }
  //   return res
  // }, [tokenChartData, chartIndex])

  const [chartData, chartDates, chartCategories, chartDuration, valueUSD, changePercent, timeLabel] = useMemo(() => {
    if (!tokenChartData) return [null, null, null, null, 0, 0, '']
    let _data
    let _chartDuration
    let _isWeekly = false
    let _timeType, _timeLabel

    switch (timeframe) {
      case timeframeOptions['WEEK']:
        _data = tokenChartData.daily?.slice(-7) || []
        _chartDuration = AnalyticChart.ONE_WEEK_CHART
        _timeLabel = 'Past Week'
        _timeType = 'OneWeek'
        break
      case timeframeOptions['MONTH']:
        _data = tokenChartData.daily?.slice(-31) || []
        _chartDuration = AnalyticChart.ONE_MONTH_CHART
        _timeLabel = 'Past Month'
        _timeType = 'OneMonth'
        break
      case timeframeOptions['ALL_TIME']:
        _data = tokenChartData.weekly
        _chartDuration = AnalyticChart.THREE_MONTH_CHART
        _isWeekly = true
        _timeLabel = dayjs().format('MMM DD, YYYY')
        _timeType = 'OneDay'
        break
      default:
        break
    }

    switch (chartIndex) {
      case ChartIndexes.Volume:
        return [
          _data.map((i) => i[_isWeekly ? 'weeklyVolumeUSD' : 'dailyVolumeUSD']),
          _data.map((i) => i.date),
          _data,
          _chartDuration,
          tokenData?.[`volume${_timeType}USD`],
          tokenData?.[`volume${_timeType}ChangeUSD`],
          _timeLabel,
        ]
      case ChartIndexes.Liquidity:
        return [
          _data.map((i) => i[_isWeekly ? 'weeklyLiquidityUSD' : 'totalLiquidityUSD']),
          _data.map((i) => i.date),
          _data,
          _chartDuration,
          tokenData?.[`liquidityUSD${_timeType}`],
          tokenData?.[`liquidityUSD${_timeType}Change`],
          _timeLabel,
        ]
      case ChartIndexes.Price:
        return [
          _data.map((i) => i[_isWeekly ? 'weeklyPriceUSD' : 'priceUSD']),
          _data.map((i) => i.date),
          _data,
          _chartDuration,
          tokenData?.[`price${_timeType}USD`],
          tokenData?.[`price${_timeType}ChangeUSD`],
          _timeLabel,
        ]
      default:
        return [null, null, null, null, 0, 0, '']
    }
  }, [tokenChartData, tokenData, chartIndex, timeframe])

  // const currentVal = useMemo(() => {
  //   switch (chartIndex) {
  //     case ChartIndexes.Volume:
  //       return Number(volumeOneDayUSD)
  //     case ChartIndexes.Liquidity:
  //       return Number(totalLiquidityUSD)
  //     case ChartIndexes.Price:
  //       return Number(priceUSD)
  //     default:
  //       return
  //   }
  // }, [priceUSD, volumeOneDayUSD, totalLiquidityUSD, chartIndex])

  // const currentValChange = useMemo(() => {
  //   switch (chartIndex) {
  //     case ChartIndexes.Volume:
  //       return Number(volumeOneDayChangeUSD)
  //     case ChartIndexes.Liquidity:
  //       return Number(liquidityUSDOneDayChange)
  //     case ChartIndexes.Price:
  //       return Number(priceOneDayChangeUSD)
  //     default:
  //       return
  //   }
  // }, [priceOneDayChangeUSD, volumeOneDayChangeUSD, liquidityUSDOneDayChange, chartIndex])

  return (
    <div className='w-full'>
      <div className='max-w-[1104px] mx-auto w-full'>
        <AnalyticsHeader data={tokenData} />
        <div className='md:flex items-center justify-between mt-7 md:mt-9'>
          <div className='w-full md:w-auto'>
            <div className='flex flex-wrap items-center space-x-1.5'>
              <div className='flex items-center space-x-2'>
                <img alt='' src={asset ? asset.logo : '/images/tokens/UKNOWN.png'} className='w-[30px] md:w-9' />
                <div className='text-[23px] md:text-[27px] leading-8 font-bold'>{asset?.name ?? name}</div>
              </div>
              <p className='text-lg md:text-xl leading-6 text-secondaryGrayText font-bold'>({asset?.symbol ?? symbol})</p>
            </div>
            <div className='flex items-center space-x-3 mt-[7px] md:mt-2.5'>
              <p className='text-base md:text-lg leading-5 font-semibold'>${formatAmount(priceUSD)}</p>
              <PercentChange value={priceOneDayChangeUSD} />
            </div>
          </div>
          <div className='md:flex items-center md:space-x-5 mt-[18px] md:mt-0'>
            <div className='sm:flex items-center sm:space-x-3 md:space-x-5 mt-3 md:mt-0'>
              <Button
                size='action'
                padding='10px 35px'
                height='auto'
                className='sm:w-full !w-auto !block'
                onClick={() => {
                  navigate(`/liquidity`)
                }}
              >
                <div>ADD/REMOVE LIQUIDITY</div>
              </Button>
              <Button
                size='action'
                padding='10px 35px'
                height='auto'
                className='sm:w-full !w-auto !block sm:mt-0 mt-3'
                onClick={() => {
                  navigate(`/swap?outputCurrency=${tokenAddress}`)
                }}
              >
                <div>SWAP</div>
              </Button>
            </div>
          </div>
        </div>
        <div className='lg:flex lg:space-x-6 space-y-[26px] lg:space-y-0 mt-5 lg:mt-6'>
          <div className={cn('bg-background rounded-[15px] w-full lg:w-1/4 ', colorMode === 'dark' ? '' : 'card-shadow')}>
            <div className='px-6 py-[22px] h-full'>
              <div className=''>
                <p className='leading-5 text-base font-medium'>Total Liquidity</p>
                <div className='flex items-center leading-[26px] text-[22px] space-x-3 mt-1'>
                  <span className='font-semibold'>${formatAmount(liquidityUSD)}</span>
                  <PercentChange value={liquidityUSDOneDayChange} />
                </div>
              </div>
              <div className='mt-6 lg:mt-10'>
                <p className='leading-5 text-base font-medium'>Volume (24hrs)</p>
                <div className='flex items-center leading-[26px] text-[22px] space-x-3 mt-1'>
                  <span className='font-semibold'>${formatAmount(volumeOneDayUSD)}</span>
                  <PercentChange value={volumeOneDayChangeUSD} />
                </div>
              </div>
              <div className='mt-6 lg:mt-10'>
                <p className='leading-5 text-base font-medium'>Transactions (24hrs)</p>
                <div className='flex items-center leading-[26px] text-[22px] space-x-3 mt-1'>
                  <span className='font-semibold'>{formatAmount(oneDayTxns)}</span>
                  <PercentChange value={txnChange} />
                </div>
              </div>
              <div className='mt-6 lg:mt-10'>
                <p className='leading-5 text-base font-medium'>Contract Address</p>
                <div>
                  <div
                    className={cn(
                      'text-primaryText inline-flex items-end gap-2 cursor-pointer w-auto transition-all duration-300 ',
                      colorMode === 'dark' ? 'hover:text-[#7AF182]' : 'hover:text-[#444444]',
                    )}
                    onClick={() => {
                      window.open(getScanUrl() + 'address/' + tokenAddress, '_blank')
                    }}
                  >
                    <p className='leading-[1] text-lg font-medium mt-1'>{tokenAddress && tokenAddress.slice(0, 6) + '...' + tokenAddress.slice(38, 42)}</p>
                    <LinkIcon {...linkIconStyle} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={cn('bg-background rounded-[15px] w-full lg:w-3/4 ', colorMode === 'dark' ? '' : 'card-shadow')}>
            <div className='px-6 py-[22px] h-full'>
              <div className='flex items-center justify-between sm:flex-nowrap flex-wrap gap-x-2 gap-y-0'>
                <div className='pb-[8px] flex items-center w-full space-x-5'>
                  {Object.values(ChartIndexes).map((item, idx) => {
                    return (
                      <div
                        key={idx}
                        className={`${chartIndex === item ? '' : 'text-grayText'} font-medium cursor-pointer text-lg leading-[22px]`}
                        onClick={() => setChartIndex(item)}
                      >
                        {item}
                      </div>
                    )
                  })}
                </div>
                <TimeframeFilter filter={timeframe} setFilter={setTimeframe} />
              </div>
              <div className='mt-3'>
                <div className='flex items-center space-x-3'>
                  <div className='font-bold text-[22px] lg:text-[30px]'>${formatAmount(valueUSD)}</div>
                  <PercentChange value={changePercent} />
                </div>
                <div className='text-[10px] lg:text-[15px]'>{timeLabel}</div>
              </div>
              {chartData && (
                <AreaChart
                  data={chartData}
                  yAxisValues={getYAXISValuesAnalytics(chartData)}
                  dates={chartDates}
                  height={275}
                  categories={getChartDates(chartCategories, chartDuration)}
                />
              )}
            </div>
          </div>
        </div>
        <div className='w-full mt-10'>
          <div className='w-full'>
            <p className='text-[22px] text-primaryText md:text-[27px] leading-8 font-bold'>{asset?.symbol ?? symbol} Pools</p>
          </div>
          <PairsTable pairsData={pairsWithImg} searchText={asset?.symbol ?? symbol} />
        </div>
      </div>
    </div>
  )
}

export default TokenDetails
