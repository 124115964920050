import React, { useContext, useMemo, useState } from 'react'
import { useColorMode, defineStyle } from '@chakra-ui/react'
import AnalytcisHeader from '../../components/pages/analyticsHeader'
import { useAllTokenData } from '../../context/TokenData'
import { BaseAssetsConetext } from '../../context/BaseAssetsConetext'
import TokensTable from '../../components/pages/tokensTable'
import { formatAmount } from '../../utils/formatNumber'
import { cn } from '../../helpers/tailwind'
import SearchIcon from '../../components/icons/search'

const TokenTabs = ['All Tokens']

const searchIconStyle = defineStyle({
  width: '16px',
  height: 'auto',
})

const AnalyticsTokens = () => {
  const { colorMode } = useColorMode()
  const [tableType, setTableType] = useState(TokenTabs[0])
  const [searchText, setSearchText] = useState('')
  const tokens = useAllTokenData()

  // const { bookmarkTokens } = useBookmarkTokens()
  const assets = useContext(BaseAssetsConetext)

  const tokensWithImg = useMemo(() => {
    if (Object.keys(tokens).length > 0)
      return Object.values(tokens).map((token) => {
        const found = assets.find((ele) => ele.address.toLowerCase() === token.id)
        return {
          ...token,
          name: found ? found.name : token.name,
          symbol: found ? found.symbol : token.symbol,
          logo: found ? found.logo : '/images/tokens/UKNOWN.png',
        }
      })
    return []
  }, [tokens, assets])

  const topMovers = useMemo(() => {
    return (
      tokensWithImg &&
      tokensWithImg
        .sort((a, b) => {
          return parseFloat(b['volumeOneDayUSD']) - parseFloat(a['volumeOneDayUSD'])
        })
        .slice(0, 5)
    )
  }, [tokensWithImg])

  // const favoriteTokens = useMemo(() => {
  //   return (
  //     tokensWithImg &&
  //     tokensWithImg
  //       .sort((a, b) => {
  //         return parseFloat(b['oneDayVolumeUSD']) - parseFloat(a['oneDayVolumeUSD'])
  //       })
  //       .filter((ele) => {
  //         const tokenIndex = bookmarkTokens.indexOf(ele.id)
  //         return tokenIndex > -1
  //       })
  //   )
  // }, [tokensWithImg, bookmarkTokens])

  return (
    <>
      <div className='w-full'>
        <div className='max-w-[1104px] mx-auto w-full'>
          <AnalytcisHeader />
          <div className='w-full mt-10'>
            <div className={cn('w-full bg-background px-5 py-[15px] rounded-[15px] ', colorMode === 'dark' ? '' : 'card-shadow')}>
              <div className={`lg:flex items-center`}>
                {topMovers.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      className={`lg:w-1/5  ${idx !== topMovers.length - 1 ? 'lg:border-r border-b lg:border-b-0 border-primaryBorder' : ''} ${
                        idx === 0 ? 'pb-[15px] lg:pb-0' : 'lg:px-4 py-[15px] lg:py-0'
                      }`}
                    >
                      <div className='flex items-center lg:items-end space-x-3 md:space-x-1.5'>
                        <div className='flex items-end space-x-2'>
                          <img alt='' src={item.logo} className='w-[30px] h-[30px] mb-1' />
                          <div className=''>
                            <span className='text-[17px] lg:text-lg leading-5 font-medium'>
                              {item.symbol.length > 12 ? item.symbol.slice(0, 10) + '...' : item.symbol}
                            </span>
                            <p className='text-[13px] lg:text-[15px] leading-5 font-medum'>${formatAmount(item.priceOneDayUSD)}</p>
                          </div>
                        </div>
                        <div
                          className={`px-1.5 py-1 leading-5 text-sm rounded-md ${
                            item.priceOneDayChangeUSD > 0
                              ? `bg-primary ${colorMode === 'dark' ? 'text-[#7AF182]' : ''}`
                              : item.priceOneDayChangeUSD < 0
                              ? `bg-danger ${colorMode === 'dark' ? 'text-[#F26262]' : ''}`
                              : 'bg-grayBackground'
                          }`}
                        >
                          {item.priceOneDayChangeUSD > 0 ? '+' : '-'}
                          {formatAmount(Math.abs(item.priceOneDayChangeUSD))}%
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            {/* top tokens */}
            <div className='w-full mt-[30px] md:mt-[50px]'>
              <div className='w-full flex flex-col sm:flex-row justify-between items-center'>
                <div className='flex items-center space-x-6'>
                  {TokenTabs.map((item, idx) => {
                    return (
                      <button
                        onClick={() => {
                          setTableType(item)
                        }}
                        key={idx}
                        className={`${item === tableType ? '' : 'text-grayText'} text-lg leading-8 f-f-fg font-medium`}
                      >
                        {item}
                      </button>
                    )
                  })}
                </div>
                <div className='max-w-44 rounded flex items-center relative mt-4 md:mt-0'>
                  <div className={cn('pointer-events-none absolute left-3 top-[4px] ', colorMode === 'dark' ? 'text-[#444444]' : 'text-[#121212]')}>
                    <SearchIcon {...searchIconStyle} />
                  </div>
                  <input
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    placeholder='Search Tokens'
                    className={cn(
                      'bg-inputBackground placeholder-grayText h-full w-full text-primaryText text-sm pl-10 pr-4 py-[7px] rounded-[20px] border-[1px] outline-none transition-all duration-300 ',
                      colorMode === 'dark' ? 'border-inputBackground focus:border-[#7AF182]' : 'border-primaryBorder focus:bg-[#7AF182]',
                    )}
                  />
                </div>
              </div>
              <TokensTable tokensData={tokensWithImg} searchText={searchText} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AnalyticsTokens
