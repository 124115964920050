import gql from 'graphql-tag'

export const SWAPX_GLOBAL_CHART = gql`
  query swapxDayDatas($startTime: Int!, $skip: Int!) {
    dayDatas(first: 100, skip: $skip, where: { date_gt: $startTime }, orderBy: date, orderDirection: asc) {
      date
      volumeUSD
      tvlUSD
      feesUSD
    }
  }
`
// export const SWAPX_GLOBAL_DATA = (block) => {
//   const qString = `
//     query globalData {
//       poolFactories ${block ? `(block: { number: ${block} })` : ''} {
//         totalVolumeUSD
//         totalValueLockedUSD
//         totalFeesUSD
//         txCount
//         poolCount
//       }
//     }
//   `
//   return gql(qString)
// }

export const SWAPX_GLOBAL_DATA = (date) => {
  const qString = `
    query poolDayDatas {
      poolDayDatas ${date ? `(where: {date: ${date}})` : ''} {
        date
        feesUSD
        volumeUSD
        tvlUSD
      }
    }
  `
  return gql(qString)
}

export const GET_SWAPX_BLOCKS = (timestamps) => {
  let queryString = 'query blocks {'
  queryString += timestamps.map((timestamp) => {
    return `t${timestamp}:blocks(first: 1, orderBy: timestamp, orderDirection: desc, where: { timestamp_gt: ${timestamp}, timestamp_lt: ${timestamp + 600} }) {
      number
    }`
  })
  queryString += '}'
  return gql(queryString)
}

export const TOKENS_FROM_ADDRESSES_V3 = (blockNumber, tokens) => {
  let tokenString = `[`
  tokens.map((address) => {
    return (tokenString += `"${address}",`)
  })
  tokenString += ']'
  const queryString =
    `
        query tokens {
          tokens(where: {id_in: ${tokenString}},` +
    (blockNumber ? `block: {number: ${blockNumber}} ,` : ``) +
    ` orderBy: totalValueLockedUSD, orderDirection: desc, subgraphError: allow) {
            id
            symbol
            name
            decimals
            derivedEth
            volumeUSD
            volume
            txCount
            totalValueLocked
            untrackedVolumeUSD
            feesUSD
            totalValueLockedUSD
            totalValueLockedUSDUntracked
          }
        }
        `

  return gql(queryString)
}

export const TokenFieldsFragmentDoc = gql`
  fragment TokenFields on Token {
    id
    symbol
    name
    decimals
    derivedOKB
    tokenDayData(orderBy: date, orderDirection: desc, first: 1) {
      priceUSD
    }
  }
`

export const AllTokensDocument = gql`
  query AllTokens {
    tokens {
      ...TokenFields
    }
  }
  ${TokenFieldsFragmentDoc}
`

// export const SWAPX_TOKENS = gql`
//     query tokens($date: Int!) {
//       tokens {
//         id
//         name
//         symbol
//         decimals
//         tokenDayData(orderBy: date, orderDirection: asc, first:1, where: {date_gte: $date}) {
//           date,
//           priceUSD
//           volume
//           volumeUSD
//           totalValueLocked
//           totalValueLockedUSD
//           untrackedVolumeUSD
//           feesUSD
//           txCount
//         }
//       }
//     }
// `

export const SWAPX_TOKENS = (date) => {
  const queryString = `
      query tokens {
        tokens {
          id
          name
          symbol
          decimals
          volumeUSD
          feesUSD
          txCount
          totalValueLockedUSD
          tokenDayData(orderBy: date, orderDirection: asc, first:1, ${!date ? '' : 'where: {date_gte: $date}'}) {
            date,
            priceUSD
            volume
            volumeUSD
            totalValueLocked
            totalValueLockedUSD
            untrackedVolumeUSD
            feesUSD
            txCount
          }
        }
      }
        `

  return gql(queryString)
}

// export const SWAPX_CURRENT_TOKENS = gql`
//     query tokens {
//       tokens {
//         id
//         name
//         symbol
//         decimals
//         volumeUSD
//         feesUSD
//         txCount
//         totalValueLockedUSD
//       }
//     }
// `

export const TOKEN_CHART = gql`
  query tokenDayDatas($tokenAddr: String!, $startTime: Int!) {
    tokens(where: { id: $tokenAddr }) {
      tokenDayData(first: 100, skip: $skip, orderBy: date, orderDirection: desc, where: { date_gt: $startTime }) {
        id
        date
        priceUSD
        volume
        volumeUSD
        totalValueLocked
        totalValueLockedUSD
        untrackedVolumeUSD
        feesUSD
        txCount
      }
    }
  }
`

export const TOP_POOLS_BY_TOKEN = gql`
  query topPools($address: Bytes!) {
    pools(
      first: 100
      orderBy: totalValueLockedUSD
      orderDirection: desc
      where: { or: [{ token0_contains_nocase: $address }, { token1_contains_nocase: $address }] }
    ) {
      id
    }
  }
`

export const PAIRS_FROM_ADDRESSES = (blockNumber, pools) => {
  let poolString = `[`
  pools.map((address) => {
    return (poolString += `"${address}",`)
  })
  poolString += ']'
  const queryString =
    `
        query pools {
          pools(where: {id_in: ${poolString}},` +
    (blockNumber ? `block: {number: ${blockNumber}} ,` : ``) +
    ` orderBy: totalValueLockedUSD, orderDirection: desc, subgraphError: allow) {
            id
            feesUSD
            token0 {
                id
                symbol
                name
                decimals
            }
            token1 {
                id
                symbol
                name
                decimals
            }
            token0Price
            token1Price
            volumeUSD
            txCount
            totalValueLockedToken0
            totalValueLockedToken1
            totalValueLockedUSD
            totalValueLockedUSDUntracked
            untrackedVolumeUSD
            feesUSD
          }
        }
        `
  return gql(queryString)
}

export const TOP_POOLS = (count) => gql`
  query topPools {
    pools(
      first: ${count}
      orderBy: totalValueLockedUSD
      orderDirection: desc
      subgraphError: allow
    ) {
      id
    }
  }
`

export const PAIR_TRANSACTIONS = gql`
  query transactions($address: Bytes!) {
    mints: poolMints(first: 50, orderBy: timestamp, orderDirection: desc, where: { pool: $address }) {
      timestamp
      transaction {
        id
      }
      pool {
        token0 {
          id
          symbol
        }
        token1 {
          id
          symbol
        }
      }
      owner
      sender
      origin
      amount0
      amount1
      amountUSD
    }
    swaps: poolSwaps(first: 50, orderBy: timestamp, orderDirection: desc, where: { pool: $address }) {
      timestamp
      transaction {
        id
      }
      pool {
        token0 {
          id
          symbol
        }
        token1 {
          id
          symbol
        }
      }
      origin
      amount0
      amount1
      amountUSD
    }
    burns: poolBurns(first: 50, orderBy: timestamp, orderDirection: desc, where: { pool: $address }) {
      timestamp
      transaction {
        id
      }
      pool {
        token0 {
          id
          symbol
        }
        token1 {
          id
          symbol
        }
      }
      owner
      amount0
      amount1
      amountUSD
    }
  }
`

export const PAIR_CHART = gql`
  query pairDayDatas($pairAddress: Bytes!, $skip: Int!, $startTime: Int!) {
    poolDayDatas(first: 1000, skip: $skip, orderBy: date, orderDirection: asc, where: { pool: $pairAddress, date_gt: $startTime }) {
      id
      date
      tvlUSD
      volumeUSD
      volumeToken0
      volumeToken1
      token0Price
      token1Price
      feesUSD
    }
  }
`
