import React, { createContext, useContext, useReducer, useMemo, useCallback, useEffect } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { client } from '../apollo/client'
import { HOURLY_PAIR_RATES } from '../apollo/queries'
import { isAddress, getBlocksFromTimestamps, splitQuery } from '../utils/analyticsHelper'
import { STABLE_FEE, timeframeOptions, VOLATILE_FEE } from '../config/constants'
import { useLatestBlocks } from './Application'
import { getSwapxPairChartData, getSwapxPairTransactions, getSwapxTopPairs } from '../utils/swapxGraph'

const UPDATE = 'UPDATE'
const UPDATE_PAIR_TXNS = 'UPDATE_PAIR_TXNS'
const UPDATE_CHART_DATA = 'UPDATE_CHART_DATA'
const UPDATE_TOP_PAIRS = 'UPDATE_TOP_PAIRS'
const UPDATE_HOURLY_DATA = 'UPDATE_HOURLY_DATA'

dayjs.extend(utc)

export function safeAccess(object, path) {
  return object ? path.reduce((accumulator, currentValue) => (accumulator && accumulator[currentValue] ? accumulator[currentValue] : null), object) : null
}

const PairDataContext = createContext()

function usePairDataContext() {
  return useContext(PairDataContext)
}

function reducer(state, { type, payload }) {
  switch (type) {
    case UPDATE: {
      const { pairAddress, data } = payload
      return {
        ...state,
        [pairAddress]: {
          ...state?.[pairAddress],
          ...data,
        },
      }
    }

    case UPDATE_TOP_PAIRS: {
      const { topPairs } = payload
      let added = {}
      topPairs.map((pair) => {
        if (pair) {
          return (added[pair.id] = pair)
        }
      })
      return {
        ...state,
        ...added,
      }
    }

    case UPDATE_PAIR_TXNS: {
      const { address, transactions } = payload
      return {
        ...state,
        [address]: {
          ...(safeAccess(state, [address]) || {}),
          txns: transactions,
        },
      }
    }
    case UPDATE_CHART_DATA: {
      const { address, chartData } = payload
      return {
        ...state,
        [address]: {
          ...(safeAccess(state, [address]) || {}),
          chartData,
        },
      }
    }

    case UPDATE_HOURLY_DATA: {
      const { address, hourlyData, timeWindow } = payload
      return {
        ...state,
        [address]: {
          ...state?.[address],
          hourlyData: {
            ...state?.[address]?.hourlyData,
            [timeWindow]: hourlyData,
          },
        },
      }
    }

    default: {
      throw Error(`Unexpected action type in DataContext reducer: '${type}'.`)
    }
  }
}

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {})

  // update pair specific data
  const update = useCallback((pairAddress, data) => {
    dispatch({
      type: UPDATE,
      payload: {
        pairAddress,
        data,
      },
    })
  }, [])

  const updateTopPairs = useCallback((topPairs) => {
    dispatch({
      type: UPDATE_TOP_PAIRS,
      payload: {
        topPairs,
      },
    })
  }, [])

  const updatePairTxns = useCallback((address, transactions) => {
    dispatch({
      type: UPDATE_PAIR_TXNS,
      payload: { address, transactions },
    })
  }, [])

  const updateChartData = useCallback((address, chartData) => {
    dispatch({
      type: UPDATE_CHART_DATA,
      payload: { address, chartData },
    })
  }, [])

  const updateHourlyData = useCallback((address, hourlyData, timeWindow) => {
    dispatch({
      type: UPDATE_HOURLY_DATA,
      payload: { address, hourlyData, timeWindow },
    })
  }, [])

  return (
    <PairDataContext.Provider
      value={useMemo(
        () => [
          state,
          {
            update,
            updatePairTxns,
            updateChartData,
            updateTopPairs,
            updateHourlyData,
          },
        ],
        [state, update, updatePairTxns, updateChartData, updateTopPairs, updateHourlyData],
      )}
    >
      {children}
    </PairDataContext.Provider>
  )
}

const getHourlyRateData = async (pairAddress, startTime, latestBlock) => {
  try {
    const utcEndTime = dayjs.utc()
    let time = startTime

    // create an array of hour start times until we reach current hour
    const timestamps = []
    while (time <= utcEndTime.unix() - 3600) {
      timestamps.push(time)
      time += 3600
    }

    // backout if invalid timestamp format
    if (timestamps.length === 0) {
      return []
    }

    // once you have all the timestamps, get the blocks for each timestamp in a bulk query
    let blocks

    blocks = await getBlocksFromTimestamps(timestamps, 100)

    // catch failing case
    if (!blocks || blocks?.length === 0) {
      return []
    }

    if (latestBlock) {
      blocks = blocks.filter((b) => {
        return parseFloat(b.number) <= parseFloat(latestBlock)
      })
    }

    const result = await splitQuery(HOURLY_PAIR_RATES, client, [pairAddress], blocks, 100)

    // format token ETH price results
    let values = []
    for (var row in result) {
      let timestamp = row.split('t')[1]
      if (timestamp) {
        values.push({
          timestamp,
          rate0: parseFloat(result[row]?.token0Price),
          rate1: parseFloat(result[row]?.token1Price),
        })
      }
    }

    let formattedHistoryRate0 = []
    let formattedHistoryRate1 = []

    // for each hour, construct the open and close price
    for (let i = 0; i < values.length - 1; i++) {
      formattedHistoryRate0.push({
        timestamp: values[i].timestamp,
        open: parseFloat(values[i].rate0),
        close: parseFloat(values[i + 1].rate0),
      })
      formattedHistoryRate1.push({
        timestamp: values[i].timestamp,
        open: parseFloat(values[i].rate1),
        close: parseFloat(values[i + 1].rate1),
      })
    }

    return [formattedHistoryRate0, formattedHistoryRate1]
  } catch (e) {
    console.log(e)
    return [[], []]
  }
}

export function useHourlyRateData(pairAddress, timeWindow) {
  const [state, { updateHourlyData }] = usePairDataContext()
  const chartData = state?.[pairAddress]?.hourlyData?.[timeWindow]
  const [latestBlock] = useLatestBlocks()

  useEffect(() => {
    const currentTime = dayjs.utc()
    const windowSize = timeWindow === timeframeOptions.MONTH ? 'month' : 'week'
    const startTime = timeWindow === timeframeOptions.ALL_TIME ? 1672876800 : currentTime.subtract(1, windowSize).startOf('hour').unix()

    async function fetch() {
      let data = await getHourlyRateData(pairAddress, startTime, latestBlock)
      updateHourlyData(pairAddress, data, timeWindow)
    }
    if (!chartData) {
      fetch()
    }
  }, [chartData, timeWindow, pairAddress, updateHourlyData, latestBlock])

  return chartData
}

/**
 * Get all the current and 24hr changes for a pair
 */
export function usePairData(pairAddress) {
  const [state, { updateTopPairs }] = usePairDataContext()
  const pairData = state?.[pairAddress]

  useEffect(() => {
    async function fetchData() {
      const topPairs = await getSwapxTopPairs(300)
      const pairs = topPairs.map((pair) => {
        // if (!pair.isFusion) {
        //   if (pair.isStable) pair.fee = STABLE_FEE * 1000000
        //   else pair.fee = VOLATILE_FEE * 1000000
        // }
        return pair
      })
      topPairs && pairs && updateTopPairs(pairs)
    }
    if (!pairData && isAddress(pairAddress)) {
      fetchData()
    }
  }, [updateTopPairs, pairData])

  return pairData || {}
}

/**
 * Get most recent txns for a pair
 */
export function usePairTransactions(pairAddress, pairData) {
  const [state, { updatePairTxns }] = usePairDataContext()
  const pairTxns = state?.[pairAddress]?.txns
  useEffect(() => {
    async function checkForTxns() {
      if (!pairTxns) {
        // let transactions
        // if (pairData.isFusion) {
        //   transactions = await getPairTransactionsFusion(pairAddress)
        // } else {
        //   transactions = await getPairTransactionsV1(pairAddress)
        // }
        const transactions = await getSwapxPairTransactions(pairAddress)
        updatePairTxns(pairAddress, transactions)
      }
    }
    if (Object.keys(pairData).length > 0) {
      checkForTxns()
    }
  }, [pairTxns, pairAddress, pairData, updatePairTxns])
  return pairTxns || []
}

export function usePairChartData(pairAddress, pairData) {
  const [state, { updateChartData }] = usePairDataContext()
  const chartData = state?.[pairAddress]?.chartData

  useEffect(() => {
    async function checkForChartData() {
      if (!chartData) {
        // let data
        // if (pairData.isFusion) {
        //   data = await getPairChartDataFusion(pairAddress)
        // } else {
        //   data = await getPairChartData(pairAddress, pairData.isStable)
        // }
        const data = await getSwapxPairChartData(pairAddress)
        updateChartData(pairAddress, data)
      }
    }
    if (Object.keys(pairData).length > 0) {
      checkForChartData()
    }
  }, [chartData, pairAddress, pairData, updateChartData])
  return chartData
}

export function useAllPairData() {
  const [state, { updateTopPairs }] = usePairDataContext()

  const data = useMemo(() => {
    let pairs = Object.values(state)
    let result = {}
    pairs.map((pair) => {
      if (!pair.isFusion) {
        if (pair.isStable) pair.fee = STABLE_FEE * 1000000
        else pair.fee = VOLATILE_FEE * 1000000
      }
      return (result[pair.id] = pair)
    })
    return result
  }, [state])

  useEffect(() => {
    async function fetchData() {
      const topPairs = await getSwapxTopPairs(300)
      topPairs && updateTopPairs(topPairs)
    }
    if (!data || Object.keys(data).length === 0) {
      fetchData()
    }
  }, [updateTopPairs, data])

  return data || {}
}

export function useBulkPairData(pairList) {
  const [state, { updateTopPairs }] = usePairDataContext()

  const data = useMemo(() => {
    const pairs = Object.values(state).filter((pair) => pairList.includes(pair.id))
    let result = {}
    pairs.map((pair) => {
      return (result[pair.id] = pair)
    })
    return result
  }, [state, pairList])

  // const combinedVolume = useTokenDataCombined(offsetVolumes)

  useEffect(() => {
    async function fetchData() {
      const topPairs = await getSwapxTopPairs(300)
      // const topPairs = await getTopPairsTotal(300)
      topPairs && updateTopPairs(topPairs)
    }
    if (!data || Object.keys(data).length === 0) {
      fetchData()
    }
  }, [updateTopPairs, data])

  return data || {}
}

export default Provider
