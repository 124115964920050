import React, { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import TabFilter from '../../common/TabFilter'

const Tabs = ['OVERVIEW', 'TOKENS', 'PAIRS']

const getSubRoute = (item) => {
  return item === Tabs[0] ? '' : item === Tabs[1] ? '/tokens' : '/pairs'
}

const AnalyticsHeader = ({ data }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const activeTab = useMemo(() => {
    const arr = pathname.split('/')
    let result
    switch (arr[2]) {
      case 'pairs':
        result = Tabs[2]
        break
      case 'pair':
        result = Tabs[2]
        break
      case 'tokens':
        result = Tabs[1]
        break
      case 'token':
        result = Tabs[1]
        break

      default:
        result = Tabs[0]
        break
    }
    return result
  }, [pathname])

  return (
    <>
      <h1 className='text-[34px] md:text-[45px] font-semibold font-heading text-primaryText'>SwapX Analytics</h1>
      <div className='flex justify-center items-center mt-5 lg:my-8'>
        <TabFilter data={Tabs} filter={activeTab} setFilter={(item) => navigate(`/analytics${getSubRoute(item)}`)} />
      </div>
      {pathname.split('/')?.[2] !== 'pair' && (
        <div className='flex justify-center mt-5 lg:mt-8 w-full'>
          {data && (
            <p className='text-[15px] leading-[15px]'>
              <span
                className='cursor-pointer'
                onClick={() => {
                  navigate(`/analytics`)
                }}
              >
                Analytics
              </span>
              &nbsp;{'>'}&nbsp;
              <span
                className='cursor-pointer'
                onClick={() => {
                  navigate(`/analytics/${data.token0 ? 'pairs' : 'tokens'}`)
                }}
              >
                {data.token0 ? 'Pairs' : 'Tokens'}
              </span>
              &nbsp;{'>'}&nbsp;
              <span className='font-medium'>{data.token0 ? data.token0.symbol + '/' + data.token1.symbol : data.symbol}</span>&nbsp;
              <span>({data.id && data.id.slice(0, 6) + '...' + data.id.slice(38, 42)})</span>
            </p>
          )}
        </div>
      )}
    </>
  )
}

export default AnalyticsHeader
