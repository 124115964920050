import React, { useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, useColorMode, defineStyle } from '@chakra-ui/react'
import dayjs from 'dayjs'

import { useGlobalChartData, useGlobalData } from '../../../context/GlobalData'
import { formatAmount } from '../../../utils/formatNumber'
import { useAllPairData } from '../../../context/PairData'
import { BaseAssetsConetext } from '../../../context/BaseAssetsConetext'
import PairsTable from '../pairsTable'
import { useAllTokenData } from '../../../context/TokenData'
import TokensTable from '../tokensTable'
import PercentChange from '../../common/PercentChange'
import { getChartDates, getYAXISValuesAnalytics } from '../../../utils/analyticsHelper'
import AreaChart from '../../common/AreaChart'
import { cn } from '../../../helpers/tailwind'
import RightPlayArrowIcon from '../../icons/right-play-arrow'
import TimeframeFilter from '../../common/TimeframeFilter'
import { AnalyticChart, timeframeOptions } from '../../../config/constants'

const rightArrowStyle = defineStyle({
  width: 'auto',
  height: '10px',
})

const transformStyle = defineStyle({
  transitionProperty: 'transform',
  transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
  transitionDuration: '500ms',
})

const Index = () => {
  const globalData = useGlobalData()
  const globalChartData = useGlobalChartData()
  const tokens = useAllTokenData()
  const pairs = useAllPairData()
  const assets = useContext(BaseAssetsConetext)
  const navigate = useNavigate()

  const { colorMode } = useColorMode()

  const [tokenButtonHover, setTokenButtonHover] = React.useState(false)
  const [pairButtonHover, setPairButtonHover] = React.useState(false)
  const [liquidityTimeType, setLiquidityTimeType] = React.useState(timeframeOptions['WEEK'])
  const [volumeTimeType, setVolumeTimeType] = React.useState(timeframeOptions['WEEK'])
  const [feesTimeType, setFeesTimeType] = React.useState(timeframeOptions['WEEK'])

  const [liquidityData, liquidityDates, liquidityCategories, liquidityDuration, liquidityUSD, liquidityChange, liquidityTimeLabel] = useMemo(() => {
    if (globalChartData && Object.keys(globalData).length > 0) {
      let _data
      if (liquidityTimeType === timeframeOptions['ALL_TIME']) {
        _data = globalChartData.weekly
        return [
          _data?.map((i) => i.weeklyLiquidityUSD),
          _data.map((i) => i.date),
          _data,
          AnalyticChart.SIX_MONTH_CHART,
          globalData.liquidityOneDayUSD,
          globalData.liquidityOneDayChangeUSD,
          dayjs().format('MMM DD, YYYY'),
        ]
      } else if (liquidityTimeType === timeframeOptions['MONTH']) {
        _data = globalChartData.daily?.slice(-31) || []
        return [
          _data.map((i) => i.totalLiquidityUSD),
          _data.map((i) => i.date),
          _data,
          AnalyticChart.ONE_MONTH_CHART,
          globalData.liquidityOneMonthUSD,
          globalData.liquidityOneMonthChangeUSD,
          'Past Month',
        ]
      } else {
        _data = globalChartData.daily?.slice(-7) || []
        return [
          _data.map((i) => i.totalLiquidityUSD),
          _data.map((i) => i.date),
          _data,
          AnalyticChart.ONE_WEEK_CHART,
          globalData.liquidityOneWeekUSD,
          globalData.liquidityOneWeekChangeUSD,
          'Past Week',
        ]
      }
    }
    return [null, null, null, 0, 0, 0, '']
  }, [liquidityTimeType, globalChartData, globalData])

  const [volumeData, volumeDates, volumeCategories, volumeDuration, volumeUSD, volumeChange, volumeTimeLabel] = useMemo(() => {
    if (globalChartData && Object.keys(globalData).length > 0) {
      let _data
      if (volumeTimeType === timeframeOptions['ALL_TIME']) {
        _data = globalChartData.weekly
        return [
          _data.map((i) => i.weeklyVolumeUSD),
          _data.map((i) => i.date),
          _data,
          AnalyticChart.SIX_MONTH_CHART,
          globalData.volumeOneDayUSD,
          globalData.volumeOneDayChange,
          dayjs().format('MMM DD, YYYY'),
        ]
      } else if (volumeTimeType === timeframeOptions['MONTH']) {
        _data = globalChartData.daily?.slice(-31) || []
        return [
          _data.map((i) => i.dailyVolumeUSD),
          _data.map((i) => i.date),
          _data,
          AnalyticChart.ONE_MONTH_CHART,
          globalData.volumeOneMonthUSD,
          globalData.volumeOneMonthChange,
          'Past Month',
        ]
      } else {
        _data = globalChartData.daily?.slice(-7) || []
        return [
          _data.map((i) => i.dailyVolumeUSD),
          _data.map((i) => i.date),
          _data,
          AnalyticChart.ONE_WEEK_CHART,
          globalData.volumeOneWeekUSD,
          globalData.volumeOneWeekChange,
          'Past Week',
        ]
      }
    }
    return [null, null, null, 0, 0, 0, '']
  }, [volumeTimeType, globalChartData, globalData])

  const [feesData, feesDates, feesCategories, feesDuration, feesUSD, feesChange, feesTimeLabel] = useMemo(() => {
    if (globalChartData && Object.keys(globalData).length > 0) {
      let _data
      if (feesTimeType === timeframeOptions['ALL_TIME']) {
        _data = globalChartData.weekly
        return [
          _data.map((i) => i.weeklyFeesUSD),
          _data.map((i) => i.date),
          _data,
          AnalyticChart.SIX_MONTH_CHART,
          globalData.feesOneDayUSD,
          globalData.feesOneDayChange,
          dayjs().format('MMM DD, YYYY'),
        ]
      } else if (feesTimeType === timeframeOptions['MONTH']) {
        _data = globalChartData.daily?.slice(-31) || []
        return [
          _data.map((i) => i.feesUSD).filter((i) => i !== undefined),
          _data.map((i) => i.date),
          _data,
          AnalyticChart.ONE_MONTH_CHART,
          globalData.feesOneMonthUSD,
          globalData.feesOneMonthChange,
          'Past Month',
        ]
      } else {
        _data = globalChartData.daily?.slice(-7) || []
        return [
          _data.map((i) => i.feesUSD),
          _data.map((i) => i.date),
          _data,
          AnalyticChart.ONE_WEEK_CHART,
          globalData.feesOneWeekUSD,
          globalData.feesOneWeekChange,
          'Past Week',
        ]
      }
    }
    return [null, null, null, 0, 0, 0, '']
  }, [feesTimeType, globalChartData, globalData])

  const tokensWithImg = useMemo(() => {
    if (Object.keys(tokens).length > 0)
      return Object.values(tokens)
        .map((token) => {
          const found = assets.find((ele) => ele.address.toLowerCase() === token.id)
          return {
            ...token,
            name: found ? found.name : token.name,
            symbol: found ? found.symbol : token.symbol,
            logo: found ? found.logo : '/images/tokens/UKNOWN.png',
          }
        })
        .slice(0, 10)
    return []
  }, [tokens])

  const pairsWithImg = useMemo(() => {
    if (Object.keys(pairs).length > 0)
      return Object.values(pairs)
        .map((pair) => {
          const found0 = assets.find((ele) => ele.address.toLowerCase() === pair.token0.id)
          const found1 = assets.find((ele) => ele.address.toLowerCase() === pair.token1.id)
          return {
            ...pair,
            token0: {
              ...pair.token0,
              name: found0 ? found0.name : pair.token0.name,
              symbol: found0 ? found0.symbol : pair.token0.symbol,
              logo: found0 ? found0.logo : '/images/tokens/UKNOWN.png',
            },
            token1: {
              ...pair.token1,
              name: found1 ? found1.name : pair.token1.name,
              symbol: found1 ? found1.symbol : pair.token1.symbol,
              logo: found1 ? found1.logo : '/images/tokens/UKNOWN.png',
            },
          }
        })
        .slice(0, 10)
    return []
  }, [pairs])

  const details = useMemo(() => {
    if (Object.keys(globalData).length > 0) {
      const { volumeOneDayUSD, volumeOneDayChange, feesOneDayUSD, feesOneDayChange, liquidityOneDayUSD, liquidityOneDayChangeUSD } = globalData

      return [
        {
          key: '24H Volume:',
          value: volumeOneDayUSD,
          percentage: volumeOneDayChange,
        },
        {
          key: '24h Fees:',
          value: feesOneDayUSD,
          percentage: feesOneDayChange,
        },
        {
          key: 'TVL:',
          value: liquidityOneDayUSD,
          percentage: liquidityOneDayChangeUSD,
        },
      ]
    }
    return []
  }, [globalData])

  return (
    <>
      <div>
        <div className={cn('bg-background p-px rounded-[15px] w-full ', colorMode === 'dark' ? '' : 'card-shadow')}>
          <div className='px-6 py-[22px] h-full'>
            <div>
              <div className='flex items-center justify-between gap-2'>
                <div className='text-sm lg:text-base'>LIQUIDITY</div>
                <TimeframeFilter filter={liquidityTimeType} setFilter={setLiquidityTimeType} />
              </div>
              <div className='sm:mt-0 mt-1 flex items-center space-x-3'>
                <div className='font-bold text-[20px] lg:text-[27px]'>${formatAmount(liquidityUSD)}</div>
                <PercentChange value={liquidityChange} />
              </div>
              <div className='text-[10px] lg:text-[15px] mt-1'>{liquidityTimeLabel}</div>
            </div>
            {liquidityData && (
              <AreaChart
                data={liquidityData}
                yAxisValues={getYAXISValuesAnalytics(liquidityData)}
                dates={liquidityDates}
                height={275}
                categories={getChartDates(liquidityCategories, liquidityDuration)}
              />
            )}
          </div>
        </div>
      </div>
      <div className='md:flex space-y-5 md:space-y-0 md:space-x-6 mt-6'>
        <div className={cn('bg-background p-px rounded-[15px] w-full md:w-1/2 ', colorMode === 'dark' ? '' : 'card-shadow')}>
          <div className='px-6 py-[22px] h-full'>
            <div>
              <div className='flex items-center justify-between gap-2'>
                <div className='text-sm lg:text-base'>VOLUME</div>
                <TimeframeFilter filter={volumeTimeType} setFilter={setVolumeTimeType} />
              </div>
              <div className='sm:mt-0 mt-1 flex items-center space-x-3'>
                <div className='font-bold text-[20px] lg:text-[27px]'>${formatAmount(volumeUSD)}</div>
                <PercentChange value={volumeChange} />
              </div>
              <div className='text-[10px] lg:text-[15px] mt-1'>{volumeTimeLabel}</div>
            </div>
            {volumeData && (
              <AreaChart
                data={volumeData}
                yAxisValues={getYAXISValuesAnalytics(volumeData)}
                dates={volumeDates}
                height={275}
                categories={getChartDates(volumeCategories, volumeDuration)}
                type='bar'
                strokeColor={colorMode === 'dark' ? '#7af18280' : '#7AF182'}
              />
            )}
          </div>
        </div>
        <div className={cn('bg-background p-px rounded-[15px] w-full md:w-1/2 ', colorMode === 'dark' ? '' : 'card-shadow')}>
          <div className='px-6 py-[22px] h-full'>
            <div>
              <div className='flex items-center justify-between gap-2'>
                <div className='text-sm lg:text-base'>Fees</div>
                <TimeframeFilter filter={feesTimeType} setFilter={setFeesTimeType} />
              </div>
              <div className='sm:mt-0 mt-1 flex items-center space-x-3'>
                <div className='font-bold text-[20px] lg:text-[27px]'>${formatAmount(feesUSD)}</div>
                <PercentChange value={feesChange} />
              </div>
              <div className='text-[10px] lg:text-[15px] mt-1'>{feesTimeLabel}</div>
            </div>
            {feesData && (
              <AreaChart
                data={feesData}
                yAxisValues={getYAXISValuesAnalytics(feesData)}
                dates={feesDates}
                height={275}
                categories={getChartDates(feesCategories, feesDuration)}
                type='bar'
                strokeColor={colorMode === 'dark' ? '#7af18280' : '#7AF182'}
              />
            )}
          </div>
        </div>
      </div>
      <div className='w-full mt-[30px] md:mt-10'>
        <div className={cn('w-full bg-background px-4 py-4 lg:px-5 lg:py-[15px] rounded-[15px] ', colorMode === 'dark' ? '' : 'card-shadow')}>
          <div className={`lg:flex items-center`}>
            {details.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className={`lg:w-1/3 ${idx === 1 ? 'py-3 lg:py-0 my-3 lg:my-0 border-y lg:border-y-0 border-primaryBorder' : ''} ${
                    idx === 2 ? 'w-full' : 'lg:border-r border-primaryBorder w-full'
                  } ${idx === 0 ? '' : 'lg:pl-5'}`}
                >
                  <p className='leading-[19px] f-f-fg'>{item.key}</p>
                  <div className='flex items-end space-x-[10px]'>
                    <p className='text-lg lg:text-[24px] leading-5 lg:leading-[26px]'>${formatAmount(item.value)}</p>
                    <PercentChange value={item.percentage} />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        {/* top tokens */}
        <div className='w-full mt-10'>
          <div className='w-full flex items-center justify-between'>
            <p className='text-[22px] md:text-[27px] leading-8 font-[700]'>Top Tokens</p>
            <Button
              size='action'
              borderWidth={1}
              gap='0.75rem'
              padding='10px 25px'
              width='auto'
              height='auto'
              onClick={() => {
                navigate(`/analytics/tokens`)
              }}
              onMouseEnter={() => setTokenButtonHover(true)}
              onMouseLeave={() => setTokenButtonHover(false)}
            >
              <div className='normal-case leading-[1]'>View All</div>
              <div className={cn('flex items-center justify-center ', tokenButtonHover ? ' translate-x-[5px]' : '')} style={{ ...transformStyle }}>
                <RightPlayArrowIcon {...rightArrowStyle} />
              </div>
            </Button>
          </div>
          <TokensTable tokensData={tokensWithImg} />
        </div>
        {/* Top Pairs */}
        <div className='w-full mt-[30px] md:mt-[50px]'>
          <div className='w-full flex items-center justify-between'>
            <p className='text-[22px] md:text-[27px] leading-8 font-[700]'>Top Pairs</p>
            <Button
              size='action'
              borderWidth={1}
              gap='0.75rem'
              padding='10px 25px'
              width='auto'
              height='auto'
              onClick={() => {
                navigate(`/analytics/pairs`)
              }}
              onMouseEnter={() => setPairButtonHover(true)}
              onMouseLeave={() => setPairButtonHover(false)}
            >
              <div className='normal-case leading-[1]'>View All</div>
              <div className={cn('flex items-center justify-center ', pairButtonHover ? ' translate-x-[5px]' : '')} style={{ ...transformStyle }}>
                <RightPlayArrowIcon {...rightArrowStyle} />
              </div>
            </Button>
          </div>
          <PairsTable pairsData={pairsWithImg} />
        </div>
      </div>
    </>
  )
}

export default Index
