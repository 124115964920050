import { zeroAddress } from 'viem'

//import SLogo from '../../assets/tokens/sonic.png';
import AtEthLogo from '../../assets/tokens/ateth.png'
import AtollLogo from '../../assets/tokens/atoll.png'
import AurumLogo from '../../assets/tokens/aurum.png'
import frxETHLogo from '../../assets/tokens/frxeth.png'
import frxUSDLogo from '../../assets/tokens/frxusd.png'
import FXSLogo from '../../assets/tokens/fxs.png'
import GEMSxLogo from '../../assets/tokens/gemsx.png'
import SlogoSVG from '../../assets/tokens/s.svg'
import SacraLogo from '../../assets/tokens/sacra.png'
import SacraGemLogo from '../../assets/tokens/sacragem.png'
import SCETHLogo from '../../assets/tokens/sceth.png'
import SCUSDLogo from '../../assets/tokens/scusd.png'
import sfrxETHLogo from '../../assets/tokens/sfrxeth.png'
import sfrxUSDLogo from '../../assets/tokens/sfrxusd.png'
import shibaLogo from '../../assets/tokens/shiba.png'
import SiloLogo from '../../assets/tokens/silo.png'
import SOLVBTCLogo from '../../assets/tokens/solvBTC.png'
import SOLVBTCBBNLogo from '../../assets/tokens/solvbtcbbn.png'
import stkscusdLogo from '../../assets/tokens/stkscusd.png'
import STSLogo from '../../assets/tokens/sts.png'
import SUSDALogo from '../../assets/tokens/susda.png'
// import SLogo from '../../assets/tokens/sonic.png';
import SWPxLogo from '../../assets/tokens/swpx.svg'
import THCLogo from '../../assets/tokens/thc.png'
import TYSGLogo from '../../assets/tokens/tysg.png'
import USDALogo from '../../assets/tokens/usda.png'
import USDCLogo from '../../assets/tokens/usdc.png'
import USDTLogo from '../../assets/tokens/usdt.svg'
import WETHLogo from '../../assets/tokens/weth.png'
import WOOFLogo from '../../assets/tokens/woof.png'
import wsLogo from '../../assets/tokens/ws.svg'

const TOKENS_ADDRESSES = {
  fbomb: '0xedf8b632b537d5993adb5e2e15882cd791c284cb',
  hog: '0xB3804bF38bD170ef65b4De8536d19a8e3600C0A9',
  fakehog: '0xb79C1Ddd912147Eb61d944dCd6C234AEbA3A4766',
  wokb: '0x039e2fB66102314Ce7b64Ce5Ce3E5183bc94aD38',
  usdt: '0x6047828dc181963ba44974801FF68e538dA5eaF9',
  auusdc: '0xd6a69ebfa44f78cee454a2df2c77751a42f8f38c', // MAINNET // STABLE
  swpx: '0xA04BC7140c26fc9BB1F36B1A604C7A5a88fb0E70',
  frxusd: '0x80eede496655fb9047dd39d9f418d5483ed600df', // MAINNET // STABLE
  frxeth: '0x43edd7f3831b08fe70b7555ddd373c8bf65a9050', // MAINNET
  usdce: '0x29219dd400f2Bf60E5a23d13Be72B486D4038894', // MAINNET // STABLE
  sceth: '0x3bcE5CB273F0F148010BbEa2470e7b5df84C7812', // MAINNET
  sfrxusd: '0x5bff88ca1442c2496f7e475e9e7786383bc070c0', // MAINNET
  sfrxeth: '0x3ec3849c33291a9ef4c5db86de593eb4a37fde45', // MAINNET
  sts: '0xE5DA20F15420aD15DE0fa650600aFc998bbE3955', // MAINNET
  weth: '0x50c42dEAcD8Fc9773493ED674b675bE577f2634b', // MAINNET
  scusd: '0xd3DCe716f3eF535C5Ff8d041c1A41C3bd89b97aE', // MAINNET // STABLE
  stkscusd: '0x4d85ba8c3918359c78ed09581e5bc7578ba932ba', // MAINNET // STABLE
  sacra: '0x7AD5935EA295c4E743e4f2f5B4CDA951f41223c2', // MAINNET
  eggs: '0xf26Ff70573ddc8a90Bd7865AF8d7d70B8Ff019bC', // MAINNET
  sacragem: '0xfC0dd337b92Baa949bC5D25FD9A99Cb3b6873204', // MAINNET
  tysg: '0x56192E94434c4fd3278b4Fa53039293fB00DE3DB', // MAINNET
  woof: '0x7F883dA3B0d77978075f7C9c03E1B9F461CA1B8d', // MAINNET
  usda: '0xff12470a969Dd362EB6595FFB44C82c959Fe9ACc', // MAINNET // STABLE
  aurum: '0x7F144F8691CbA3d2EfD8e5bcf042f9303EE31a46', // MAINNET
  solvbtc: '0x541FD749419CA806a8bc7da8ac23D346f2dF8B77', // MAINNET
  solvbtcbbn: '0xCC0966D8418d412c599A6421b760a847eB169A8c', // MAINNET
  hedgy: '0x6fb9897896fe5d05025eb43306675727887d0b7c', // MAINNET
  fiery: '0xf97d438bc03ad0f75b83ce5714c9619880b305bc', // MAINNET
  moon: '0x486b6fa0419b33a0c7a6e4698c231d7e2f2d5299', // MAINNET
  susda: '0x2840F9d9f96321435Ab0f977E7FDBf32EA8b304f', // MAINNET // STABLE
  ateth: '0x284D81e48fBc782Aa9186a03a226690aEA5cBe0E', // MAINNET
  eco: '0x7A08Bf5304094CA4C7b4132Ef62b5EDc4a3478B7', // MAINNET
  sdog: '0x50bc6e1dff8039a4b967c1bf507ba5ea13fa18b6', // MAINNET
  atoll: '0xd1DA314f7db27DbE3c61aC172bE13d86e67688B7', // MAINNET
  goglz: '0x9fdbc3f8abc05fa8f3ad3c17d2f806c1230c4564', // MAINNET
  shiba: '0xd8794eee1bc5a883d42c737e653a237adb6acdcc', // MAINNET
  bol: '0x38628cb4cc74cbdfb6ddd877b17c1f60fcae7c2c', // MAINNET
  os: '0xb1e25689d55734fd3fffc939c4c3eb52dff8a794', // MAINNET
  anon: '0x79bbf4508b1391af3a0f4b30bb5fc4aa9ab0e07c', // MAINNET
  gemsx: '0x3c2c70b5d037ac6043ea7e6a9c62af685af1f1e8',
  mclb: '0x44E23B1F3f4511b3a7e81077Fd9F2858dF1B7579', // MAINNET
}

export const SPECIAL_TOKENS = {
  S: {
    symbol: 'S',
    logo: SlogoSVG,
    address: zeroAddress,
    name: 'Sonic',
  },
  wS: {
    symbol: 'wS',
    logo: wsLogo,
    address: TOKENS_ADDRESSES.wokb,
    name: 'Wrapped Sonic',
  },
  WSonic: {
    symbol: 'wS',
    logo: wsLogo,
    address: TOKENS_ADDRESSES.wokb,
    name: 'Wrapped Sonic',
  },
  SWPx: {
    symbol: 'SWPx',
    logo: SWPxLogo,
    address: TOKENS_ADDRESSES.swpx,
    name: 'SwapX',
  },
  USDT: {
    symbol: 'USDT',
    logo: USDTLogo,
    address: TOKENS_ADDRESSES.usdt,
    name: 'Bridged USDT (Sonic Labs)',
  },
  USDCe: {
    symbol: 'USDCe',
    logo: USDCLogo,
    address: TOKENS_ADDRESSES.usdce,
    name: 'Bridged USDC (Sonic Labs)',
  },
  scETH: {
    symbol: 'scETH',
    logo: SCETHLogo,
    address: TOKENS_ADDRESSES.sceth,
    name: 'Sonic ETH',
  },
  stS: {
    symbol: 'stS',
    logo: STSLogo,
    address: TOKENS_ADDRESSES.sts,
    name: 'Beets Staked Sonic',
  },
  WETH: {
    symbol: 'WETH',
    logo: WETHLogo,
    address: TOKENS_ADDRESSES.weth,
    name: 'Wrapped Ether on Sonic',
  },
  scUSD: {
    symbol: 'scUSD',
    logo: SCUSDLogo,
    address: TOKENS_ADDRESSES.scusd,
    name: 'Sonic USD',
  },
  SACRA: {
    symbol: 'SACRA',
    logo: SacraLogo,
    address: TOKENS_ADDRESSES.sacra,
    name: 'Sacra',
  },
  SACRA_GEM_1: {
    symbol: 'SACRA_GEM_1',
    logo: SacraGemLogo,
    address: TOKENS_ADDRESSES.sacragem,
    name: 'SacraGem',
  },
  TYSG: {
    symbol: 'TYSG',
    logo: TYSGLogo,
    address: TOKENS_ADDRESSES.tysg,
    name: 'Thank You Sonic God',
  },
  WOOF: {
    symbol: 'WOOF',
    logo: WOOFLogo,
    address: TOKENS_ADDRESSES.woof,
    name: 'Muttski',
  },
  AUR: {
    symbol: 'AUR',
    logo: AurumLogo,
    address: TOKENS_ADDRESSES.aurum,
    name: 'Aurum',
  },
  SolvBTC: {
    symbol: 'SolvBTC',
    logo: SOLVBTCLogo,
    address: TOKENS_ADDRESSES.solvbtc,
    name: 'Solv BTC',
  },
  SolvBTCBBN: {
    symbol: 'SolvBTCBBN',
    logo: SOLVBTCBBNLogo,
    address: TOKENS_ADDRESSES.solvbtcbbn,
    name: 'SolvBTC Babylon',
  },
  sUSDa: {
    symbol: 'sUSDa',
    logo: USDALogo,
    address: TOKENS_ADDRESSES.susda,
    name: 'USDa Saving Token',
  },
  USDa: {
    symbol: 'USDa',
    logo: SUSDALogo,
    address: TOKENS_ADDRESSES.usda,
    name: 'USDa',
  },
  atEth: {
    symbol: 'atEth',
    logo: AtEthLogo,
    address: TOKENS_ADDRESSES.ateth,
    name: 'Atoll ETH',
  },
  ATOLL: {
    symbol: 'ATOLL',
    logo: AtollLogo,
    address: TOKENS_ADDRESSES.atoll,
    name: 'Atoll Governance Token',
  },
  stkscUSD: {
    symbol: 'stkscUSD',
    logo: stkscusdLogo,
    address: TOKENS_ADDRESSES.stkscusd,
    name: 'Staked Sonic USD',
  },
  SHIBA: {
    symbol: 'SHIBA',
    logo: shibaLogo,
    address: TOKENS_ADDRESSES.shiba,
    name: 'Shiba Sonic',
  },
  GEMSx: {
    symbol: 'GEMSx',
    logo: GEMSxLogo,
    address: TOKENS_ADDRESSES.gemsx,
    name: 'SwapX GEMS',
  },
  frxETH: {
    symbol: 'frxETH',
    logo: frxETHLogo,
    address: '0x43edd7f3831b08fe70b7555ddd373c8bf65a9050',
    name: 'Frax Ether',
  },
  sfrxETH: {
    symbol: 'sfrxETH',
    logo: sfrxETHLogo,
    address: '0x3ec3849c33291a9ef4c5db86de593eb4a37fde45',
    name: 'Staked Frax Ether',
  },
  FXS: {
    symbol: 'FXS',
    logo: FXSLogo,
    address: '0x64445f0aecc51e94ad52d8ac56b7190e764e561a',
    name: 'Frax Share',
  },
  frxUSD: {
    symbol: 'frxUSD',
    logo: frxUSDLogo,
    address: '0x80eede496655fb9047dd39d9f418d5483ed600df',
    name: 'Frax USD',
  },
  sfrxUSD: {
    symbol: 'sfrxUSD',
    logo: sfrxUSDLogo,
    address: '0x5bff88ca1442c2496f7e475e9e7786383bc070c0',
    name: 'Staked Frax USD',
  },
  Silo: {
    symbol: 'Silo',
    logo: SiloLogo,
    address: '0x53f753e4b17f4075d6fa2c6909033d224b81e698',
    name: 'Silo Governance Token',
  },
  THC: {
    symbol: 'THC',
    logo: THCLogo,
    address: '0x17af1df44444ab9091622e4aa66db5bb34e51ad5',
    name: 'Tin Hat Cat',
  },
}
