import { Outlet } from 'react-router-dom'
import { useColorMode } from '@chakra-ui/react'
import Lottie from 'lottie-react'

import Header from '../Header'
// import Footer from '../Footer'
import DarkBg from '../../../assets/bg-dark-lottie.json'
import LightBg from '../../../assets/bg-light-lottie.json'
import { useEffect } from 'react'

const Layout = () => {
  const { colorMode } = useColorMode()

  const fixLottieSvg = () => {
    const lottieDiv = document.getElementById('lottie')
    if (lottieDiv) {
      const svgElement = lottieDiv.querySelector('svg')
      if (svgElement) {
        svgElement.setAttribute('preserveAspectRatio', 'none')
      }
    }
  }

  //! TODO: Remove this useEffect after the issue is fixed
  useEffect(() => {
    fixLottieSvg()
  }, [colorMode])

  return (
    <div>
      <Header />
      <div className='px-5 pt-[120px] pb-[100px]'>
        <div className='fixed left-0 top-0 w-full h-full z-[-1] bg-no-repeat bg-cover bg-center'>
          <Lottie
            id='lottie'
            style={{ width: '100%', height: '100%', position: 'fixed' }}
            animationData={colorMode === 'dark' ? DarkBg : LightBg}
            loop
            autoplay
          />
        </div>
        <Outlet />
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default Layout
