import React, { useMemo, useState } from 'react'
import { useColorMode } from '@chakra-ui/react'
import Pagination from '../../common/Pagination'
import { NumberOfRows, TXN_TYPE } from '../../../config/constants'
import { formatAmount } from '../../../utils/formatNumber'
import dayjs from 'dayjs'
import { getScanUrl } from '../../../utils'
import { cn } from '../../../helpers/tailwind'

function getTransactionType(event, symbol0, symbol1) {
  const formattedS0 = symbol0?.length > 8 ? symbol0.slice(0, 7) + '...' : symbol0
  const formattedS1 = symbol1?.length > 8 ? symbol1.slice(0, 7) + '...' : symbol1
  switch (event) {
    case TXN_TYPE.ADD:
      return 'Add ' + formattedS0 + ' and ' + formattedS1
    case TXN_TYPE.REMOVE:
      return 'Remove ' + formattedS0 + ' and ' + formattedS1
    case TXN_TYPE.SWAP:
      return 'Swap ' + formattedS0 + ' for ' + formattedS1
    default:
      return ''
  }
}

const formatTime = (unix) => {
  const now = dayjs()
  const timestamp = dayjs.unix(unix)

  const inSeconds = now.diff(timestamp, 'second')
  const inMinutes = now.diff(timestamp, 'minute')
  const inHours = now.diff(timestamp, 'hour')
  const inDays = now.diff(timestamp, 'day')

  if (inHours >= 24) {
    return `${inDays} ${inDays === 1 ? 'day' : 'days'} ago`
  } else if (inMinutes >= 60) {
    return `${inHours} ${inHours === 1 ? 'hour' : 'hours'} ago`
  } else if (inSeconds >= 60) {
    return `${inMinutes} ${inMinutes === 1 ? 'minute' : 'minutes'} ago`
  } else {
    return `${inSeconds} ${inSeconds === 1 ? 'second' : 'seconds'} ago`
  }
}

const SORT_FIELD = {
  TOTAL_VALUE: 'amountUSD',
  TOKEN_AMOUNT_0: 'token0Amount',
  TOKEN_AMOUNT_1: 'token1Amount',
  ACCOUNT: 'account',
  TIME: 'timestamp',
}

const HeaderOptions = [
  {
    label: 'Action',
    sort: SORT_FIELD.TOTAL_VALUE,
  },
  {
    label: 'Total Value',
    sort: SORT_FIELD.TOTAL_VALUE,
  },
  {
    label: 'Token Amount',
    sort: SORT_FIELD.TOKEN_AMOUNT_0,
  },
  {
    label: 'Token Amount',
    sort: SORT_FIELD.TOKEN_AMOUNT_1,
  },
  {
    label: 'Account',
    sort: SORT_FIELD.ACCOUNT,
  },
  {
    label: 'Time',
    sort: SORT_FIELD.TIME,
  },
]

const TransactionTable = ({ data, filter }) => {
  const { colorMode } = useColorMode()

  const [sortDirection, setSortDirection] = useState(true)
  const [sortedColumn, setSortedColumn] = useState(SORT_FIELD.TIME)
  const txnData = useMemo(() => {
    if (filter === TXN_TYPE.ALL) {
      return data
    }
    return data.filter((ele) => ele.type === filter)
  }, [filter, data])
  const [pageSize, setPageSize] = useState(NumberOfRows[0])
  const [currentPage, setCurrentPage] = useState(0)
  const handlePageClick = (event) => {
    setCurrentPage(event.selected)
  }
  const pageCount = useMemo(() => {
    return Math.ceil(txnData.length / pageSize)
  }, [txnData, pageSize])

  const sortedList = useMemo(() => {
    return (
      txnData &&
      txnData
        .sort((a, b) => {
          if (sortedColumn === SORT_FIELD.ACCOUNT) {
            return a[sortedColumn] > b[sortedColumn] ? (sortDirection ? -1 : 1) * 1 : (sortDirection ? -1 : 1) * -1
          }
          return parseFloat(a[sortedColumn]) > parseFloat(b[sortedColumn]) ? (sortDirection ? -1 : 1) * 1 : (sortDirection ? -1 : 1) * -1
        })
        .slice(pageSize * currentPage, (currentPage + 1) * pageSize)
    )
  }, [txnData, pageSize, currentPage, sortDirection, sortedColumn])

  return (
    <>
      <div
        className={cn(
          'w-full rounded-[15px] lg:px-6 lg:pb-6 lg:pt-4 lg:mt-3.5 ',
          sortedList.length > 0 ? 'lg:bg-background' : 'bg-background pb-4 pt-4 mt-3.5 ',
          colorMode === 'dark' ? '' : `${sortedList.length > 0 ? 'lg-card-shadow' : ' card-shadow'}`,
        )}
      >
        <div className='lg:flex justify-between hidden f-f-fg font-medium text-[17px] xl:text-[18px] pb-3.5 border-b border-primaryBorder'>
          {/* <div className='w-[25%]'>
            <div className={` flex items-center text-lg leading-[22px]  font-normal  space-x-3 w-full`}>
              {Object.values(TXN_TYPE).map((option, idx) => {
                return (
                  <button className={`${filter === option ? 'text-primaryText' : 'text-grayText'}`} onClick={() => setFilter(option)} key={idx}>
                    {option}
                  </button>
                )
              })}
            </div>
          </div> */}
          {HeaderOptions.map((option, idx) => {
            return (
              <div key={idx} className={cn(option.label === 'Action' ? 'w-[25%]' : 'w-[15%]', ' flex items-center')}>
                <button
                  onClick={() => {
                    setSortedColumn(option.sort)
                    setSortDirection(sortedColumn !== option.sort ? true : !sortDirection)
                  }}
                  className={`flex items-center space-x-[3px]`}
                >
                  <span>{option.label}</span>
                  {sortedColumn === option.sort && (
                    <div className='flex items-center mt-[2px]'>
                      <svg
                        className={`mr-[3px] text-primaryText ${!sortDirection ? 'rotate-180' : ''} `}
                        xmlns='http://www.w3.org/2000/svg'
                        width={12}
                        viewBox='0 0 10 6'
                      >
                        <path
                          d='M9.37495 4.22194e-07L0.625082 -3.42744e-07C0.455805 -3.57543e-07 0.309275 0.0659276 0.185627 0.197893C0.0620475 0.329822 4.82045e-07 0.48605 4.66259e-07 0.666614C4.50474e-07 0.847177 0.0620131 1.00333 0.185627 1.13544L4.56058 5.80211C4.68419 5.93389 4.83062 6 5 6C5.16938 6 5.31581 5.93389 5.43952 5.80211L9.81448 1.13544C9.93819 1.00348 10 0.847178 10 0.666615C10 0.486087 9.93819 0.329823 9.81448 0.197894C9.69062 0.0659284 9.5442 4.36989e-07 9.37495 4.22194e-07Z'
                          fill='currentColor'
                        />
                      </svg>
                    </div>
                  )}
                </button>
              </div>
            )
          })}
        </div>
        {sortedList.length > 0 ? (
          sortedList.map((item, index) => {
            return (
              <div
                key={index}
                className={cn(
                  index > 0 ? 'lg:pt-7 pt-4 ' : 'lg:pt-5 pt-4',
                  'lg:bg-transparent bg-background rounded-[15px] pb-4 lg:pb-0 lg:my-0 my-3 ',
                  colorMode === 'dark' ? '' : 'md-card-shadow',
                  'flex flex-wrap lg:flex-nowrap items-start lg:items-center w-full justify-between px-4 lg:px-0',
                )}
              >
                <div
                  className='w-full lg:pb-0 lg:w-[25%] cursor-pointer lg:font-normal font-medium'
                  onClick={() => {
                    window.open(`${getScanUrl()}tx/${item.hash}/`, '_blank')
                  }}
                >
                  <div className='leading-5 font-medium'>{getTransactionType(item.type, item.token1Symbol, item.token0Symbol)}</div>
                </div>
                <div className='w-1/2 lg:w-[15%] mt-[15px] lg:mt-0'>
                  <p className='lg:hidden text-sm f-f-fg font-semibold'>Total Value</p>
                  <div className='leading-5'>${formatAmount(item.amountUSD)}</div>
                </div>
                <div className='w-1/2 lg:w-[15%] mt-[15px] lg:mt-0'>
                  <p className='lg:hidden text-sm f-f-fg font-semibold'>Token Amount</p>
                  <div className='leading-5'>
                    {formatAmount(item.token0Amount)} {item.token0Symbol}
                  </div>
                </div>
                <div className='w-1/2 lg:w-[15%] mt-[15px] lg:mt-0'>
                  <p className='lg:hidden text-sm f-f-fg font-semibold'>Token Amount</p>
                  <div className='leading-5'>
                    {formatAmount(item.token1Amount)} {item.token1Symbol}
                  </div>
                </div>
                <div className='w-1/2 lg:w-[15%] mt-[15px] lg:mt-0'>
                  <p className='lg:hidden text-sm f-f-fg font-semibold'>Account</p>
                  <div className='flex -ml-[8px]'>
                    <div
                      className={cn(
                        'leading-5 py-[2px] px-[8px] font-medium cursor-pointer rounded-[15px] border-[1px] border-transparent hover:border-[#7AF182] transition-all duration-300 ',
                        colorMode === 'dark' ? '' : 'hover:bg-[#7AF182]',
                      )}
                      onClick={() => {
                        window.open(getScanUrl() + 'address/' + item.account, '_blank')
                      }}
                    >
                      {item.account && item.account.slice(0, 6) + '...' + item.account.slice(38, 42)}
                    </div>
                  </div>
                </div>
                <div className='w-1/2 lg:w-[15%] mt-[15px] lg:mt-0'>
                  <p className='lg:hidden text-sm f-f-fg font-semibold'>Time</p>
                  <div className='leading-5'>{formatTime(item.timestamp)}</div>
                </div>
              </div>
            )
          })
        ) : (
          <div className='lg:pt-[38.5px] lg:pb-3 lg:flex lg:items-center lg:justify-center lg:w-full lg:text-[22px] lg:leading-7 lg:font-medium flex items-center justify-center h-full w-full text-[22px] leading-7 font-medium'>
            No Results
          </div>
        )}
        {txnData.length > 10 && (
          <div className='flex md:justify-end justify-center mt-3'>
            <Pagination
              pageSize={pageSize}
              setPageSize={setPageSize}
              handlePageClick={handlePageClick}
              pageCount={pageCount}
              currentPage={currentPage}
              total={txnData.length}
              hideDetail
            />
          </div>
        )}
      </div>
    </>
  )
}

export default TransactionTable
