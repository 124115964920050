import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useColorMode } from '@chakra-ui/react'

import useGoogleAnalytics from '../../../hooks/useGoogleAnalytics'
import { connectors } from '../../../config/constants'
import { useWeb3Wagmi } from '../../../hooks/useWeb3'
import ThemeToggler from '../ThemeToggler'

import './style.scss'

const Header = () => {
  useGoogleAnalytics()
  const { colorMode } = useColorMode()
  const [connector, setConnector] = useState(null)
  const { account } = useWeb3Wagmi()
  const [selected, setSelected] = useState(false)

  useEffect(() => {
    if (account) {
      if (!connector && !selected) {
        setConnector(connectors[0])
        setSelected(false)
      }
    } else {
      setConnector(null)
    }
  }, [account, connector, selected, setSelected])

  return (
    <>
      <div className={`header-wrap fixed w-full z-[120]`}>
        <div className={`bg-background transition-all duration-300 ease-in-out border-t-[1px] border-b-[1px] border-[#12121266]`}>
          <div className='header px-4 2xl:px-12 py-5'>
            <Link to='/'>
              <img className='w-[125px] h-auto relative z-10' alt='logo' src={`/images/header/${colorMode}-logo.svg`} />
            </Link>
            <div>
              <ThemeToggler />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
