import React, { createContext, useContext, useReducer, useMemo, useCallback, useEffect } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import { getChartData, getGlobalData } from '../utils/swapxGraph'

const UPDATE_GLOBAL = 'UPDATE'
const UPDATE_CHART = 'UPDATE_CHART'
const UPDATE_ETH_PRICE = 'UPDATE_ETH_PRICE'
const ETH_PRICE_KEY = 'ETH_PRICE_KEY'

// format dayjs with the libraries that we need
dayjs.extend(utc)
dayjs.extend(weekOfYear)

const GlobalDataContext = createContext()

function useGlobalDataContext() {
  return useContext(GlobalDataContext)
}

function reducer(state, { type, payload }) {
  switch (type) {
    case UPDATE_GLOBAL: {
      const { data } = payload
      return {
        ...state,
        globalData: data,
      }
    }

    case UPDATE_CHART: {
      const { data } = payload
      return {
        ...state,
        chartData: data,
      }
    }
    case UPDATE_ETH_PRICE: {
      const { ethPrice, oneDayPrice, ethPriceChange } = payload
      return {
        [ETH_PRICE_KEY]: ethPrice,
        oneDayPrice,
        ethPriceChange,
      }
    }

    default: {
      throw Error(`Unexpected action type in DataContext reducer: '${type}'.`)
    }
  }
}

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {})
  const update = useCallback((data) => {
    dispatch({
      type: UPDATE_GLOBAL,
      payload: {
        data,
      },
    })
  }, [])

  const updateChart = useCallback((data) => {
    dispatch({
      type: UPDATE_CHART,
      payload: {
        data,
      },
    })
  }, [])

  const updateEthPrice = useCallback((ethPrice, oneDayPrice, ethPriceChange) => {
    dispatch({
      type: UPDATE_ETH_PRICE,
      payload: {
        ethPrice,
        oneDayPrice,
        ethPriceChange,
      },
    })
  }, [])
  return (
    <GlobalDataContext.Provider
      value={useMemo(
        () => [
          state,
          {
            update,
            updateChart,
            updateEthPrice,
          },
        ],
        [state, update, updateChart, updateEthPrice],
      )}
    >
      {children}
    </GlobalDataContext.Provider>
  )
}

/**
 * Hook that fetches overview data, plus all tokens and pairs for search
 */
export function useGlobalData() {
  const [state, { update }] = useGlobalDataContext()

  const data = useMemo(() => {
    return state?.globalData && state?.globalData
  }, [state])

  useEffect(() => {
    async function fetchData() {
      let globalData = await getGlobalData()
      globalData && update(globalData)
    }
    if (!data) {
      fetchData()
    }
  }, [update, data])

  return data || {}
}

export function useGlobalChartData() {
  const [state, { updateChart }] = useGlobalDataContext()

  const data = state?.chartData

  /**
   * Fetch data if none fetched or older data is needed
   */
  useEffect(() => {
    async function fetchData() {
      // historical stuff for chart
      let data = await getChartData()
      updateChart(data)
    }
    if (!data) {
      fetchData()
    }
  }, [data, updateChart])

  return data
}

export default Provider
